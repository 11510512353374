import * as React from "react";
import FeatherIcon from "feather-icons-react";

import styles from "./contact.module.css";

export const Contact = () => {
  return (
    <section className={styles.container} id="contact">
      <h1>Contact</h1>

      <div className={styles.content}>
        <div>
          <p>Aparthotel Cracovia Residence</p>
          <p>Colorfly Sp. z o.o. <br /> Spółka Komandytowa. <br /> NIP: 94522044383</p>
          <p>PL75 8808 0006 0050 0196 0363 0016 <br /> SWIFT / BIC: POLUPLPR </p>
          <p><FeatherIcon icon="map-pin" color="#c1a178"  size={20} /> Józefa Piłsudskiego 28a Street, Kraków 31-111</p>
          <p><FeatherIcon icon="phone" color="#c1a178"  size={20} /> tel. +48123149006, +48733766831</p>
          <p><FeatherIcon icon="mail" color="#c1a178"  size={20} />  hotel@aparthotelcracovia.pl</p>
        </div>
        <form action="https://formspree.io/mknqlboz" method="POST">
          <div>
            <input name="name" type="text" required />
            <div className={styles.labelbox}>
              <label>Name</label>
            </div>
          </div>
          <div>
            <input name="mail" type="text" required />
            <div className={styles.labelbox}>
              <label>E-Mail</label>
            </div>
          </div>
          <div>
            <input name="message" type="text" required />
            <div className={styles.labelbox}>
              <label>Message</label>
            </div>
          </div>
          <button>Send</button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
