import * as React from "react";
import { Link } from "gatsby"

import styles from "./apartment.module.css";

export const Apartment = ({ photo, title, link }) => {
  return (
    <Link to={link}>
      <div className={styles.container}>
        <img src={photo} />
        <div className={styles.titleContainer}>
          <p className={styles.title}>{title}</p>
        </div>
      </div>
    </Link>
  );
};

export default Apartment;
