import * as React from "react";
import FeatherIcon from "feather-icons-react";

import styles from "./footer.module.css";

export const Footer = () => {
  return (
    <footer>
      <div className={styles.regulations}>
        <a href="/hotel_regulations_en.pdf">Hotel regulations</a>
        <a href="/booking_Regulations_en.pdf">Booking regulations</a>
        <a href="/privacy_policy_en.pdf">Privacy Policy</a>
      </div>
      <div className={styles.socialmedias}>
        <a className={styles.socialmedium}>
          <FeatherIcon icon="facebook" color="#ffffff"  size={24} />
        </a>
        <a className={styles.socialmedium}>
          <FeatherIcon icon="instagram" color="#ffffff"  size={24} />
        </a>
      </div>
      <p>© 2020 Aparthotel Cracovia</p>
    </footer>
  );
};

export default Footer;
