import * as React from "react";
import { Helmet } from "react-helmet";

import "../index.css";

import Hero from "../../components/en/Hero";
import About from "../../components/en/About";
import Map from "../../components/Map";
import Apartments from "../../components/en/Apartments";
import Footer from "../../components/en/Footer";
import Contact from "../../components/en/Contact";

export const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Aparthotel Cracovia</title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Cinzel:wght@700&family=Coda&family=IBM+Plex+Serif:wght@300&display=swap" />
        <script src="https://wis.upperbooking.com/aparthotelcracoviaresidence/booking?locale=en" async></script>
      </Helmet>
      <Hero />
      <About />
      <Map />
      <Apartments />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
