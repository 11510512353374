import * as React from "react";

import Icon from "../../Icon";

import styles from "./about.module.css";

export const About = () => {
  return (
    <section className={styles.container} id="about-us">
      <h1>About us</h1>
      <p className={styles.description}>
      Aparthotel Cracovia Residence – this lovely, opened in 2020 aparthotel is located in the heart of Krakow, within the Old Town district. Piłsudskiego Street connects the Old Town with Błonia Park called the largest urban meadow, which is a favourite place of recreation for Cracovians. The main building of the National Museum and the Collegium Novum of the Jagiellonian University are located nearby. A few-minute walk separates guests from the Main Market Square, the Royal Castle and the Vistula Boulevards.
      <br /> <br />
      Our apartments are located in a historic residence dated from 1893, which after a major renovation has been converted into a modern and comfortable aparthotel. The design was inspired by the New York style, characterized by neutrality, calm forms and impeccable finish
      </p>
      <div className={styles.icons}>
        <Icon icon="map-pin" text="Strict center of the city"/>
        <Icon icon="home" text="Completely renovated facility"/>
        <Icon icon="map" text="Tourist information & Concierge services"/>
        <Icon icon="smile" text="We approach each client individually"/>
        <Icon icon="book-open" text="Our apartments are located in historical tenement"/>
        <Icon icon="list" text="Different types of apartments"/>

      </div>
    </section>
  );
};

export default About;
