import * as React from "react";

import styles from "./hero.module.css";

export const Hero = () => {
  return (
    <div className={styles.container}>
      <nav>
        <p></p>
        <div>
          <a href="/en/#about-us">About us</a>
          <a href="/en/#apartments">Apartments</a>
          <a href="/en/#contact">Contact</a>
        </div>
      </nav>

      <div className={styles.content}>
        <div>
          <p>Modernity & Elegance</p>
          <h1>Aparthotel Cracovia</h1>
          <p>28A Józefa Piłsudskiego Street, Kraków</p>
          <a href="/">Polish</a>
          <div className="profitroom"></div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

