import * as React from "react";

import Apartment from "../../Apartment";

import styles from "./apartments.module.css";

import classicSingle from "../../../../img/apartments/classic_single/1.jpg";
import classicDouble from "../../../../img/apartments/classic_double/1.jpg";
import superior from "../../../../img/apartments/superior_double/1.jpg";
import classicApartment from "../../../../img/apartments/classic_apartment/1.jpg";
import deluxeApartment from "../../../../img/apartments/deluxe_apartment/1.jpg";


export const Apartments = () => {
  return (
    <section className={styles.container} id="apartments">
      <h1>Apartaments</h1>

      <div className={styles.apartments}>
        <Apartment link="/en/apartments/classicsingle" title="Classic Single Room" photo={classicSingle}/>
        <Apartment link="/en/apartments/doubleroom" title="Classic Double/Twin Room" photo={classicDouble} />
        <Apartment link="/en/apartments/superior" title="Superior Dobule/Twin Room" photo={superior} />
        <Apartment link="/en/apartments/classicapartment" title="Classic One-Bedroom Apartment" photo={classicApartment} />
        <Apartment link="/en/apartments/deluxeapartment" title="Deluxe Two-Bedroom Apartment" photo={deluxeApartment} />
      </div>
    </section>
  );
};

export default Apartments;
